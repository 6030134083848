import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useAuth } from '@resources/contexts';

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Ingresa un correo valido')
        .min(3, 'Mínimo 3 carácteres')
        .max(50, 'Máximo 50 carácteres')
        .required('Ingresa un correo'),
    password: Yup.string()
        .min(3, 'Mínimo 3 carácteres')
        .max(50, 'Máximo 50 carácteres')
        .required('Ingresa una contraseña'),
});

const initialValues = {
    email: '',
    password: '',
};

export function LoginModal() {
    const [loading, setLoading] = useState(false);
    const { login, logout } = useAuth();

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);

            login(values.email, values.password)
                .then(() => {
                    window.location.reload();
                    //window.$('#loginModal').modal('hide');
                })
                .catch(() =>
                    setStatus(
                        'Los datos ingresados son invalidos, por favor intenta nuevamente.'
                    )
                )
                .finally(() => {
                    setSubmitting(false);
                    setLoading(false);
                });
        },
    });

    useEffect(() => {
        window.$('#loginModal').modal({ backdrop: 'static', keyboard: false });
    }, []);

    function handleLogout() {
        window.$('#loginModal').modal('hide');

        logout();
    }

    return (
        <div className="modal fade" tabIndex="-1" id="loginModal">
            <form onSubmit={formik.handleSubmit} noValidate>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title">Tu sesión expiró</h3>
                        </div>

                        <div className="modal-body">
                            {formik.status && (
                                <div className="mb-lg-15 alert alert-danger">
                                    <div className="alert-text font-weight-bold">
                                        {formik.status}
                                    </div>
                                </div>
                            )}

                            <div className="fv-row mb-10">
                                <label className="form-label fs-6 fw-bold text-dark">
                                    Correo electrónico
                                </label>
                                <input
                                    placeholder="Correo electrónico"
                                    {...formik.getFieldProps('email')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {
                                            'is-invalid':
                                                formik.touched.email &&
                                                formik.errors.email,
                                        },
                                        {
                                            'is-valid':
                                                formik.touched.email &&
                                                !formik.errors.email,
                                        }
                                    )}
                                    type="email"
                                    name="email"
                                    autoComplete="off"
                                />
                                {formik.touched.email &&
                                    formik.errors.email && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">
                                                    {formik.errors.email}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                            </div>

                            {/* begin::Form group */}
                            <div className="fv-row mb-10">
                                <div className="d-flex justify-content-between mt-n5">
                                    <div className="d-flex flex-stack mb-2">
                                        {/* begin::Label */}
                                        <label className="form-label fw-bold text-dark fs-6 mb-0">
                                            Contraseña
                                        </label>
                                        {/* end::Label */}
                                    </div>
                                </div>
                                <input
                                    placeholder="Contraseña"
                                    type="password"
                                    autoComplete="off"
                                    {...formik.getFieldProps('password')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {
                                            'is-invalid':
                                                formik.touched.password &&
                                                formik.errors.password,
                                        },
                                        {
                                            'is-valid':
                                                formik.touched.password &&
                                                !formik.errors.password,
                                        }
                                    )}
                                />
                                {formik.touched.password &&
                                    formik.errors.password && (
                                        <div className="fv-plugins-message-container">
                                            <div className="fv-help-block">
                                                <span role="alert">
                                                    {formik.errors.password}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                            </div>
                            {/* end::Form group */}
                        </div>

                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={handleLogout}
                            >
                                Log out
                            </button>
                            <button
                                className="btn btn-primary"
                                disabled={
                                    formik.isSubmitting || !formik.isValid
                                }
                            >
                                {!loading && (
                                    <span className="indicator-label">
                                        Log in
                                    </span>
                                )}
                                {loading && (
                                    <span
                                        className="indicator-progress"
                                        style={{ display: 'block' }}
                                    >
                                        loading...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
