import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    LoadingOverlay,
    Box,
    Textarea,
    Button,
    Input,
    TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { userService } from '@resources/services';
import { replaceNullsWithEmptyString } from '@resources/helpers';

export function UserModal({ id, userId, hideModal }) {
    const { t } = useTranslation();
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);

    const form = useForm({
        initialValues: {
            facebook_id: '',
            name: '',
            email: '',
            comments: '',
        },
    });

    useEffect(() => {
        setLoading(true);

        userService
            .get(userId, { include: 'pages' })
            .then((user) => {
                setUser(user);

                form.setValues(replaceNullsWithEmptyString(user));
            })
            .finally(() => setLoading(false));
    }, [id]);

    function handleSubmit(values) {
        setLoading(true);

        userService.update(userId, values).finally(() => setLoading(false));
    }

    return (
        <Modal size="lg" show={true} onHide={hideModal}>
            <Modal.Body>
                <Box pos="relative">
                    <LoadingOverlay visible={loading} overlayBlur={2} />
                    <div className="card mb-6 mb-xl-9">
                        <div className="card-body p-0">
                            <div className="row mb-6">
                                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                    <div className="d-flex flex-column">
                                        <div className="d-flex align-items-center mb-1">
                                            <span className="text-gray-800 fs-2 fw-bold me-3">
                                                {user.facebook_id}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="separator mb-4"></div>
                            <Tabs
                                id="users-tabs"
                                defaultActiveKey="general"
                                className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold mb-4"
                            >
                                <Tab
                                    eventKey="general"
                                    title={t('General')}
                                    tabClassName="nav-link text-active-primary py-5 me-6"
                                >
                                    <form
                                        onSubmit={form.onSubmit(handleSubmit)}
                                        noValidate
                                    >
                                        <TextInput
                                            disabled
                                            label={t('Facebook')}
                                            className="mb-3"
                                            {...form.getInputProps(
                                                'facebook_id'
                                            )}
                                        />

                                        <TextInput
                                            label={t('Name')}
                                            className="mb-3"
                                            {...form.getInputProps('name')}
                                        />

                                        <TextInput
                                            label={t('Email')}
                                            className="mb-3"
                                            {...form.getInputProps('email')}
                                        />

                                        <Textarea
                                            label={t('Comments')}
                                            className="mb-3"
                                            placeholder={t('Write a comment')}
                                            {...form.getInputProps('comments')}
                                        />

                                        <div className="d-flex mt-4">
                                            <Button
                                                type="submit"
                                                className="btn btn-primary ms-auto"
                                            >
                                                <i className="fa fa-save"></i>
                                                Save
                                            </Button>
                                        </div>
                                    </form>
                                </Tab>

                                <Tab
                                    eventKey="pages"
                                    title={`${t('Pages')} (${
                                        user.pages?.length
                                    })`}
                                    tabClassName="nav-link text-active-primary py-5 me-6"
                                >
                                    <div className="table-responsive">
                                        <table className="table table-sm table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Id</th>
                                                    <th>Facebook</th>
                                                    <th>{t('Name')}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {user.pages?.map((page) => (
                                                    <tr key={page.id}>
                                                        <td>{page.id}</td>
                                                        <td>
                                                            <a
                                                                href={`https://facebook.com/${page.facebook_id}`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                {
                                                                    page.facebook_id
                                                                }
                                                            </a>
                                                        </td>
                                                        <td>
                                                            {page.name}{' '}
                                                            {!!page.banned && (
                                                                <span className="badge badge-light-danger">
                                                                    Banned
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </Box>
            </Modal.Body>
        </Modal>
    );
}
