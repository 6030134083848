import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Layout34 } from '@features/layouts';
import { Home } from '@pages/Home';
import { useAuth } from '@resources/contexts';
import { getMyMenuArray } from '@resources/menu';

/**
 * Router
 * The routes of the app
 *
 * @returns {JSX.Element} A react component
 */
export function PrivateRoutes() {
    const { user } = useAuth();
    return (
        <Routes>
            <Route element={<Layout34 />}>
                <Route index element={<Home />} />

                {getMyRoutes(user.profile)}

                <Route path="*" element={<Navigate replace to="/" />} />
            </Route>
        </Routes>
    );
}

function getMyRoutes(myRole) {
    return getMyMenuArray(myRole).map((menuOption, index) => (
        <Route
            key={index}
            path={menuOption.link}
            element={menuOption.element}
        />
    ));
}
