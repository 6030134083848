import axios from 'axios';

const urlBase = 'users';

export const userService = {
    getAll(params = {}) {
        return axios.get(urlBase, { params }).then((res) => res.data);
    },

    get(id, params = {}) {
        return axios
            .get(`${urlBase}/${id}`, { params })
            .then((res) => res.data);
    },

    update(id, newValues) {
        return axios.put(`${urlBase}/${id}`, newValues).then((res) => res.data);
    },

    store(newValues) {
        return axios.post(urlBase, newValues).then((res) => res.data);
    },
};
