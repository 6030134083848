import React from 'react';

/**
 * Page loader.
 *
 * @returns {JSX.Element} A react component
 */
export function PageLoader() {
    return (
        <div className="page-loading bg-white position-absolute w-100 h-100">
            <div className="page-loader h-100 flex-column aligns-items-center justify-content-center">
                <span
                    className="spinner-border text-primary"
                    role="status"
                ></span>
                <span className="text-muted fs-6 fw-semibold mt-5">
                    Cargando...
                </span>
            </div>
        </div>
    );
}
