import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { NotificationsProvider } from '@mantine/notifications';
import { Router } from '@features/Router';
import { Compose } from '@features/components/Compose';
import {
    AppProvider,
    AuthProvider,
    GlobalModalsProvider,
} from '@resources/contexts';
import { LoadingWrapper } from '@features/components/LoadingWrapper';
import { setupAxios } from '@resources/helpers';
import store from '@resources/store';
import '@resources/i18n';
import './index.css';

setupAxios(axios);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <NotificationsProvider position="top-right" zIndex={2077}>
            <Compose
                components={[AppProvider, AuthProvider, GlobalModalsProvider]}
            >
                <LoadingWrapper>
                    <Router />
                </LoadingWrapper>
            </Compose>
        </NotificationsProvider>
    </Provider>
);
