import { useCallback, useState, useEffect } from 'react';
import { debounce } from '@resources/helpers';

/**
 * useDatabase
 * Provide the states and controls for a datatable
 *
 * @param {any[]} props - The props
 * @param {any} props.service The service to use
 * @param {string} props.query The query of search
 * @param {number} props.page The page
 * @param {number} props.limit The number of items per page
 * @param {string} props.sort The column to sort
 * @param {Map<string, string>} props.filter The filters
 * @param {number} props.debounceTime The time to wait before updating the query
 * @returns {any} states and controls
 */
export function useDatatable(params) {
    const {
        service = null,
        query = '',
        page = 1,
        limit = 15,
        sort = '',
        filter,
        debounceTime = 500,
    } = params || {};

    const [requestParams, setRequestData] = useState({
        query,
        page,
        limit,
        sort,
        filter,
    });
    const [loading, setLoading] = useState([]);
    const [data, setData] = useState([]);
    const [sortBy, setSortBy] = useState('');
    const [sortAsc, setSortAsc] = useState(true);

    useEffect(() => {
        updateList();
    }, [requestParams]);

    /**
     * updateList
     * Update the list of data
     *
     */
    function updateList() {
        if (service && service.getAll) {
            setLoading(true);
            setData([]);

            service
                .getAll(requestParams)
                .then(setData)
                .finally(() => setLoading(false));
        }
    }

    /**
     * setRequestDataSpread
     * Update the request data
     *
     * @param {object} spreadData - A object with the columns to update
     */
    function setRequestDataSpread(spreadData) {
        setRequestData((data) => ({ ...data, page: 1, ...spreadData }));
    }

    /**
     * setPage
     * Update the current page
     *
     * @param {number} page The new page
     */
    function setPage(page) {
        setRequestDataSpread({ page });
    }

    /**
     * setLimit
     * Update the limit of items to display
     *
     * @param {number} limit The limit of items to display
     */
    function setLimit(limit) {
        setRequestDataSpread({ limit });
    }

    /**
     * setQuery
     * Update the query, used to filter the records to be displayed.
     *
     * @param {string} query - The text to filter
     */
    function setQuery(query) {
        setRequestDataSpread({ query });
    }

    /**
     * setSort
     * Sort the table using a column, if this column is called 1 time then the data
     * is sorted by this column Ascending, with 2 times it is sorted Descending.
     *
     * @param {string} column - The column to sort
     */
    function setSort(column) {
        if (column !== sortBy) {
            setSortAsc(true);
        } else {
            setSortAsc((sortAsc) => !sortAsc);
        }

        setSortBy(column);
        setRequestDataSpread({
            sort: `${sortBy && sortAsc ? '-' : ''}${sortBy}`,
        });
    }

    return {
        data,
        controls: {
            setPage,
            setLimit,
            setQuery: useCallback(debounce(setQuery, debounceTime), []),
            sort: {
                column: sortBy,
                asc: sortAsc,
                setColumn: setSort,
            },
        },
        loading,
        updateList,
        requestParams,
    };
}
