import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@resources/contexts';

export function UserCard() {
    const { t } = useTranslation();
    const { user, logout } = useAuth();

    return (
        <div className="app-navbar flex-shrink-0">
            <div
                className="app-navbar-item ms-5"
                id="kt_header_user_menu_toggle"
            >
                <div
                    className="cursor-pointer symbol symbol-35px symbol-md-40px"
                    data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                >
                    <img
                        className="symbol symbol-circle symbol-35px symbol-md-40px"
                        src="/assets/media/avatars/blank.png"
                        alt="user"
                    />
                </div>
                <div
                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
                    data-kt-menu="true"
                >
                    <div className="menu-item px-3">
                        <div className="menu-content d-flex align-items-center px-3">
                            <div className="symbol symbol-50px me-5">
                                <img
                                    alt="Logo"
                                    src="/assets/media/avatars/blank.png"
                                />
                            </div>
                            <div className="d-flex flex-column">
                                <div className="fw-bold d-flex align-items-center fs-5">
                                    {user.first_name} {user.last_name}
                                    <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">
                                        Id: {user.id}
                                    </span>
                                </div>
                                <a
                                    href="#"
                                    className="fw-semibold text-muted text-hover-primary fs-7"
                                >
                                    {user.email}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="separator my-2"></div>
                    <div className="menu-item px-5">
                        <a href="#" onClick={logout} className="menu-link px-5">
                            {t('Logout')}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
