import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from './Header';
import { Footer } from './Footer';
import { LoginModal } from './components/LoginModal';

/**
 * Layout 34 of metronic v8.1.6
 * The principal layout of the app
 * @param {any[]} props - Functional component props
 * @param {JSX.Element} props.children - The children
 * @returns A react component
 */
export function Layout34({ children }) {
    useEffect(() => {
        window.KTComponents.init();
    }, []);

    return (
        <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
            <div
                className="app-page flex-column flex-column-fluid"
                id="kt_app_page"
            >
                <Header />
                <div
                    className="app-wrapper flex-column flex-row-fluid"
                    id="kt_app_wrapper"
                >
                    <Outlet />

                    <Footer />

                    <LoginModal />
                </div>
            </div>
        </div>
    );
}
