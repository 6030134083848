import React from 'react';
import classNames from 'classnames';

/**
 * THeadColumn
 * Represents a column of the THead component
 *
 * @param {any[]} props - The custom props of the component
 * @param {IColumn} props.column - The props of the column
 * @param {IControls} props.controls - The props of the column
 * @returns {JSX.Element} A react component
 */
function THeadColumn({
    column: { sortable = true, align = 'left', width = 'auto', ...column },
    controls = {},
}) {
    const isShortable = column.isSortable || typeof column.column === 'string';
    const isSortedColumn = controls.sort.column === column.column;

    /**
     * Callend when the column is clicked
     */
    function handleOnClickColumn() {
        if (isShortable) {
            controls.sort.setColumn(column.column);
        }
    }

    return (
        <th
            style={{ width }}
            className={classNames({
                clickable: isShortable,
                sorting: isSortedColumn,
                sorting_asc: isSortedColumn && controls.sort.asc,
                sorting_desc: isSortedColumn && !controls.sort.asc,
                ...column.className,
            })}
            align={align}
            onClick={handleOnClickColumn}
        >
            <span className="flex w-full text-capitalize">
                {column.title
                    ? column.title
                    : typeof column.column === 'string'
                    ? column.column
                    : ''}
            </span>
        </th>
    );
}

/**
 * THead
 * Represents the Head of the table
 *
 * @param {any[]} props - The custom props of the component
 * @param {IColumn[]} props.columns - A array of columns
 * @param {IControls} props.controls - The props of the column
 * @returns {JSX.Element} - A react component
 */
export function THead({ columns, controls }) {
    return (
        <thead className="border-b">
            <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                {columns?.map((column, index) => {
                    return (
                        <THeadColumn
                            controls={controls}
                            key={index}
                            column={column}
                        />
                    );
                })}
            </tr>
        </thead>
    );
}
