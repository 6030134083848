import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useAuth } from '@resources/contexts';
import { Button } from '@mantine/core';

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Ingresa un correo valido')
        .min(3, 'Mínimo 3 carácteres')
        .max(50, 'Máximo 50 carácteres')
        .required('Ingresa un correo'),
    password: Yup.string()
        .min(3, 'Mínimo 3 carácteres')
        .max(50, 'Máximo 50 carácteres')
        .required('Ingresa una contraseña'),
});

const initialValues = {
    email: '',
    password: '',
};

export function Login() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { login } = useAuth();

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);

            login(values.email, values.password)
                .catch(() =>
                    setStatus(
                        'Los datos ingresados son invalidos, por favor intenta nuevamente.'
                    )
                )
                .finally(() => {
                    setSubmitting(false);
                    setLoading(false);
                });
        },
    });
    return (
        <form className="form w-100" onSubmit={formik.handleSubmit} noValidate>
            {/* begin::Heading */}
            <div className="text-center mb-10">
                <h1 className="text-dark mb-3">{t('Login')}</h1>
            </div>
            {/* begin::Heading */}

            {formik.status && (
                <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">
                        {formik.status}
                    </div>
                </div>
            )}

            {/* begin::Form group */}
            <div className="fv-row mb-10">
                <label className="form-label fs-6 fw-bold text-dark">
                    {t('Email')}
                </label>
                <input
                    placeholder={t('Email')}
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                            'is-invalid':
                                formik.touched.email && formik.errors.email,
                        },
                        {
                            'is-valid':
                                formik.touched.email && !formik.errors.email,
                        }
                    )}
                    type="email"
                    name="email"
                    autoComplete="off"
                />
                {formik.touched.email && formik.errors.email && (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                            <span role="alert">{formik.errors.email}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className="fv-row mb-10">
                <div className="d-flex justify-content-between mt-n5">
                    <div className="d-flex flex-stack mb-2">
                        {/* begin::Label */}
                        <label className="form-label fw-bold text-dark fs-6 mb-0">
                            {t('Password')}
                        </label>
                        {/* end::Label */}
                    </div>
                </div>
                <input
                    placeholder={t('Password')}
                    type="password"
                    autoComplete="off"
                    {...formik.getFieldProps('password')}
                    className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                            'is-invalid':
                                formik.touched.password &&
                                formik.errors.password,
                        },
                        {
                            'is-valid':
                                formik.touched.password &&
                                !formik.errors.password,
                        }
                    )}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                            <span role="alert">{formik.errors.password}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className="text-center">
                <Button
                    type="submit"
                    id="kt_sign_in_submit"
                    className="btn btn-lg btn-primary w-100 mb-5"
                    disabled={formik.isSubmitting || !formik.isValid}
                    loading={loading}
                >
                    {t('Login')}
                </Button>
            </div>
            {/* end::Action */}
        </form>
    );
}
