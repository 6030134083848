import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '@resources/contexts';

/**
 * Home
 * The home page component
 *
 * @returns {JSX.Element} A react component
 */
export function Home() {
    const { user } = useAuth();
    return (
        <>
            <div id="kt_app_toolbar" className="app-toolbar py-6">
                <div
                    id="kt_app_toolbar_container"
                    className="app-container container-xxl d-flex align-items-start"
                >
                    <div className="d-flex flex-column flex-row-fluid">
                        <div className="d-flex align-items-center pt-1">
                            <ul className="breadcrumb breadcrumb-separatorless fw-semibold">
                                <li className="breadcrumb-item text-white fw-bold lh-1">
                                    <Link to="/" className="text-white">
                                        <i className="fonticon-home text-gray-700 fw-bold fs-3"></i>
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <span className="svg-icon svg-icon-4 svg-icon-gray-700 mx-n1">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </span>
                                </li>
                                <li className="breadcrumb-item text-white fw-bold lh-1">
                                    Dashboards
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex flex-stack flex-wrap flex-lg-nowrap gap-4 gap-lg-10 pt-13 pb-6">
                            <div className="page-title me-5">
                                <h1 className="page-heading d-flex text-white fw-bold fs-2 flex-column justify-content-center my-0">
                                    Bienvenido, {user.first_name}
                                    <span className="page-desc text-gray-700 fw-semibold fs-6 pt-3">
                                        Your are #1 seller across market’s
                                        Marketing Category
                                    </span>
                                </h1>
                            </div>
                            <div className="d-flex align-self-center flex-center flex-shrink-0">
                                <a
                                    href="#"
                                    className="btn btn-sm border btn-active-color-primary btn-custom px-4"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_invite_friends"
                                >
                                    <span className="svg-icon svg-icon-3">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect
                                                opacity="0.3"
                                                x="2"
                                                y="2"
                                                width="20"
                                                height="20"
                                                rx="5"
                                                fill="currentColor"
                                            />
                                            <rect
                                                x="10.8891"
                                                y="17.8033"
                                                width="12"
                                                height="2"
                                                rx="1"
                                                transform="rotate(-90 10.8891 17.8033)"
                                                fill="currentColor"
                                            />
                                            <rect
                                                x="6.01041"
                                                y="10.9247"
                                                width="12"
                                                height="2"
                                                rx="1"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </span>
                                    <span className="ms-n1">Invite</span>
                                </a>
                                <a
                                    href="#"
                                    className="btn btn-sm btn-active-color-primary border btn-custom ms-3 px-4"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_new_target"
                                >
                                    Set Your Target
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="app-container container-xxl">
                <div
                    className="app-main flex-column flex-row-fluid"
                    id="kt_app_main"
                >
                    <div className="d-flex flex-column flex-column-fluid">
                        <div
                            id="kt_app_content"
                            className="app-content flex-column-fluid"
                        >
                            <div className="row g-5 g-xl-8">
                                <div className="col-xl-12">
                                    <div className="card bgi-position-y-bottom bgi-position-x-end bgi-no-repeat bgi-size-cover min-h-250px bg-body mb-5 mb-xl-8">
                                        <div className="card-body d-flex flex-column justify-content-center ps-lg-12">
                                            <h3 className="text-dark fs-2qx fw-bold mb-7">
                                                We are working
                                                <br />
                                                to boost lovely mood
                                            </h3>
                                            <div className="m-0">
                                                <a
                                                    href="#"
                                                    className="btn btn-dark fw-semibold px-6 py-3"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#kt_modal_create_app"
                                                >
                                                    Create a Store
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
