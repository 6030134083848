import classNames from 'classnames';
import { usePagination } from '@resources/hooks';
import React from 'react';

/**
 * PageItem
 * Represents a option for the {@link Pagination} component
 *
 * @param {any[]} props - The custom props of the component
 * @param {boolean} props.active - Represents if the page item is active
 * @param {boolean} props.disabled - Represents if the page item is disabled
 * @param {boolean} props.separator - Represents if the page item is a separator
 * @param {Function} props.onClick - Is called when the page item is clicked
 * @param {JSX.Element} props.children - The children
 * @returns {JSX.Element} A react component
 */
function PageItem({
    active = false,
    disabled = false,
    separator = false,
    onClick,
    children,
    aditionalClassName,
}) {
    /**
     * handleClick
     * Called when the page item is clicked
     *
     * @param {React.MouseEvent} event - The Mouse event
     * @returns {void}
     */
    function handleClick(event) {
        event.preventDefault();

        if (!disabled && !!onClick) {
            onClick();
        }
    }

    return !separator ? (
        <li
            className={classNames('paginate_button page-item', {
                disabled,
                active,
            })}
            onClick={handleClick}
        >
            <a href="#" className="page-link">
                {children}
            </a>
        </li>
    ) : (
        <span className="page-item">...</span>
    );
}

/**
 * Pagination
 * Represents a component of pagination
 *
 * @param {any[]} props - The custom props of the component
 * @param {number} props.currentPage - The current page
 * @param {number} props.siblingCount - The number of intermediate elements to show
 * @param {number} props.perPage - The elements to show per page
 * @param {number} props.total - The total of elements
 * @param {Function} props.onChange - Is called when a page is selected
 * @returns {JSX.Element} A react component
 */
export function Pagination({
    currentPage,
    siblingCount = 3,
    perPage,
    total,
    onChange,
}) {
    const { totalPages, middleRange, leftDots, rightDots } = usePagination({
        currentPage,
        siblingCount,
        perPage,
        totalRecords: total,
    });

    const from = perPage * (currentPage - 1) + 1;
    const to = Math.min(perPage * currentPage, total);

    /**
     * handleSelectPage
     * Called when a page is selected
     *
     * @param {number} page - The page selected
     * @returns {void}
     */
    function handleSelectPage(page) {
        if (onChange) {
            onChange(Math.min(Math.max(page, 1), totalPages));
        }
    }

    return (
        <>
            {totalPages > 1 && (
                <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="kt_ecommerce_products_table_paginate"
                >
                    <ul className="pagination">
                        <PageItem
                            disabled={currentPage === 1}
                            onClick={() => handleSelectPage(currentPage - 1)}
                        >
                            <i className="previous"></i>
                        </PageItem>

                        <PageItem
                            active={currentPage === 1}
                            onClick={() => handleSelectPage(1)}
                        >
                            1
                        </PageItem>

                        {leftDots && <PageItem separator />}
                        {!!middleRange.length &&
                            middleRange.map((page) => {
                                return (
                                    <PageItem
                                        key={page}
                                        active={currentPage === page}
                                        onClick={() => handleSelectPage(page)}
                                    >
                                        {page}
                                    </PageItem>
                                );
                            })}
                        {rightDots && <PageItem separator />}

                        <PageItem
                            active={currentPage === totalPages}
                            onClick={() => handleSelectPage(totalPages)}
                        >
                            {totalPages}
                        </PageItem>
                        <PageItem
                            disabled={currentPage === totalPages}
                            onClick={() => handleSelectPage(currentPage + 1)}
                        >
                            <i className="next"></i>
                        </PageItem>
                    </ul>
                </div>
            )}
        </>
    );
}
