import React from 'react';
import { Link } from 'react-router-dom';
import { getMyMenuArray } from '@resources/menu';
import { useAuth } from '@resources/contexts';

export function Menu() {
    return (
        <div
            className="app-header-menu app-header-mobile-drawer align-items-stretch"
            data-kt-drawer="true"
            data-kt-drawer-name="app-header-menu"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="250px"
            data-kt-drawer-direction="start"
            data-kt-drawer-toggle="#kt_app_header_menu_toggle"
            data-kt-swapper="true"
            data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
            data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
        >
            <div
                className="menu menu-rounded menu-active-bg menu-state-primary menu-column menu-lg-row menu-title-gray-700 menu-icon-gray-500 menu-arrow-gray-500 menu-bullet-gray-500 my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0"
                id="kt_app_header_menu"
                data-kt-menu="true"
            >
                <MenuOptions />
            </div>
        </div>
    );
}

function MenuOptions() {
    const { user } = useAuth();

    function getMenuOptions(myRole) {
        return getMyMenuArray(myRole).reduce((menu, currentMenuOption) => {
            const menuIndex = menu.findIndex(
                (m) => m.title === currentMenuOption.group
            );

            if (menuIndex == -1) {
                let menuOption = {};
                if (currentMenuOption.group) {
                    menuOption = {
                        title: currentMenuOption.group,
                        submenu: [currentMenuOption],
                    };
                } else {
                    menuOption = currentMenuOption;
                }

                menu.push(menuOption);
            } else {
                menu[menuIndex].submenu.push(currentMenuOption);
            }

            return menu;
        }, []);
    }

    return getMenuOptions(user.profile).map((menuOption, menuIndex) => (
        <MenuOption key={menuIndex} {...menuOption} />
    ));
}

function MenuOption(menuOptionProps) {
    const submenuOptions = (menuOptionProps.submenu || []).map(
        (submenuOption, submenuIndex) => {
            return <SubMenuOption key={submenuIndex} {...submenuOption} />;
        }
    );

    return (
        <div
            className="menu menu-rounded menu-active-bg menu-state-primary menu-column menu-lg-row menu-title-gray-700 menu-icon-gray-500 menu-arrow-gray-500 menu-bullet-gray-500 my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0"
            id="kt_app_header_menu"
            data-kt-menu="true"
        >
            <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="bottom-start"
                className="menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2"
            >
                {menuOptionProps.link ? (
                    <Link className="menu-link" to={menuOptionProps.link}>
                        <span className="menu-title">
                            {menuOptionProps.title}
                        </span>
                        {!!submenuOptions.length && (
                            <span className="menu-arrow"></span>
                        )}
                    </Link>
                ) : (
                    <span className="menu-link">
                        <span className="menu-title">
                            {menuOptionProps.title}
                        </span>
                        {!!submenuOptions.length && (
                            <span className="menu-arrow"></span>
                        )}
                    </span>
                )}
                {!!submenuOptions.length && (
                    <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown px-lg-2 py-lg-4 w-lg-200px">
                        {submenuOptions}
                    </div>
                )}
            </div>
        </div>
    );
}

function SubMenuOption({ title, link = '', icon }) {
    return (
        <div className="menu-item">
            <Link className="menu-link" to={link}>
                {icon ? (
                    <span className="menu-icon">
                        <span className="svg-icon svg-icon-3">{icon}</span>
                    </span>
                ) : (
                    <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                    </span>
                )}

                <span className="menu-title">{title}</span>
            </Link>
        </div>
    );
}
