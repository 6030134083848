import axios from 'axios';
import Cookies from 'js-cookie';
import { AUTH } from '@resources/constants';

export const AuthService = {
    login(email, password) {
        return axios
            .post('login', {
                email, password
            })
            .then(res => {
                if (!res.data.token) {
                    Promise.reject({
                        error: 'Missing token'
                    });
                }

                Cookies.set(AUTH.COOKIE, res.data.token, {
                    expires: 365 // days
                });

                return res.data;
            });
    },

    getMe() {
        if (!Cookies.get(AUTH.COOKIE)) {
            return Promise.reject({});
        }

        return axios.get('me').then(res => res.data)
    },

    logout() {
        Cookies.remove(AUTH.COOKIE);
    },
}
