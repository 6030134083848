import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOverlay, Box } from '@mantine/core';
import { THead } from './THead';
import { TBody } from './TBody';
import { Pagination } from '../Pagination';

/**
 * Table
 * Represents a datatable component
 *
 * @param {any[]} props - The custom props of the component
 * @param {string} props.name - Represents the name of the current table
 * @param {Function[]} props.controls - All controls of the table
 * @param {array} props.columns - Represents the settings of every column
 * @param {number[]} props.optionsPerPage - Represents the available options of the limit of elements per page.
 * @param {IResponsePaginated} props.data - Represents the data of the table.
 * @param {JSX.Element} props.header - A custom header
 * @param {boolean} props.showPagination - represents whether pagination is to be displayed
 * @param {JSX.Element} props.children - The children
 * @returns {JSX.Element} A react component
 */
export function Table(props) {
    const { t } = useTranslation();
    const {
        name,
        controls,
        columns,
        optionsPerPage = [15, 20, 50, 100],
        data,
        header,
        showPagination = true,
        loading = false,
        children,
    } = props || {};

    /**
     * Called when the limit selector changes
     *
     * @param {React.ChangeEvent<HTMLSelectElement>} e The event
     */
    function handleChangeLimit(e) {
        const target = e.target;
        const value = Number(target.value);

        controls.setLimit(value);
    }

    /**
     * Called when the search input changes
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e The event
     */
    function handleChangeSearch(e) {
        const value = e.target.value;

        controls.setQuery(value);
    }

    return (
        <Box pos="relative">
            <LoadingOverlay visible={loading} overlayBlur={2} />
            <div className="card card-flush">
                <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                    <div className="card-title">
                        <div className="d-flex align-items-center position-relative my-1">
                            <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        opacity="0.5"
                                        x="17.0365"
                                        y="15.1223"
                                        width="8.15546"
                                        height="2"
                                        rx="1"
                                        transform="rotate(45 17.0365 15.1223)"
                                        fill="currentColor"
                                    />
                                    <path
                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </span>
                            <input
                                type="text"
                                className="form-control form-control-solid w-250px ps-14"
                                onKeyUp={handleChangeSearch}
                                placeholder={`${t('Search')}...`}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <div className="table-responsive">
                        <table
                            id={name}
                            className="table dataTable align-middle table-row-dashed fs-6 gy-1"
                        >
                            {React.cloneElement(header || <THead />, {
                                columns,
                                controls,
                            })}
                            {React.cloneElement(children || <TBody />, {
                                columns,
                                data,
                            })}
                        </table>
                    </div>

                    {showPagination && (
                        <div className="row">
                            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                                <div className="dataTables_length">
                                    <label>
                                        <select
                                            onChange={handleChangeLimit}
                                            value={data.per_page}
                                            className="form-select form-select-sm form-select-solid"
                                        >
                                            {optionsPerPage.map((option) => {
                                                return (
                                                    <option
                                                        key={option}
                                                        value={option}
                                                    >
                                                        {option}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                                <Pagination
                                    currentPage={data.current_page}
                                    perPage={data.per_page}
                                    total={data.total}
                                    onChange={controls.setPage}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Box>
    );
}
