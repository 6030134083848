/**
 * Get a nested value from an object
 *
 * @param {Object} obj - The object to get the value from
 * @param {String} path - The path to the value
 * @returns {Any} The value at the path
 */
export function getNestedValue(obj, path) {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}
