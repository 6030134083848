export default {
    translations: {
        Login: 'Login',
        Logout: 'Logout',
        'Sign Up': 'Sign Up',
        Email: 'Email',
        Password: 'Password',
        Search: 'Search',
        Users: 'Users',
        Name: 'Name',
        Pages: 'Pages',
        Comments: 'Comments',
        'Write a comment': 'Write a comment here...',
    },
};
