export default {
    translations: {
        Login: 'Iniciar sesión',
        Logout: 'Cerrar sesión',
        'Sign up': 'Regístrate',
        Email: 'Correo electrónico',
        Password: 'Contraseña',
        Search: 'Buscar',
        Users: 'Usuarios',
        Name: 'Nombre',
        Pages: 'Páginas',
        Comments: 'Comentarios',
        'Write a comment': 'Escribe un comentario aquí...',
    },
};
