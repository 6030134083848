import { useMemo } from 'react';
import { range } from '@resources/helpers';

/**
 * usePagination
 * Provides the statuses representing the paging options
 *
 * @param {any[]} props - The custom props of the component
 * @param {number} props.totalRecords - The number of records
 * @param {number} props.perPage - The records by page
 * @param {number} props.siblingCount - The number of sibling
 * @param {number} props.currentPage - The current page
 * @returns {any} The pagination options
 */
export function usePagination({
    totalRecords,
    perPage,
    siblingCount,
    currentPage,
}) {
    const paginationRange = useMemo(() => {
        let [leftDots, rightDots, middleRange] = [false, false, []];

        const totalPages = Math.ceil(totalRecords / perPage);
        const siblingToShow = Math.min(siblingCount, totalPages - 2);
        const middleSibling = Math.floor(siblingToShow / 2);

        const enableDots = totalPages - 2 > siblingCount;
        if (enableDots) {
            if (currentPage > 2 + middleSibling) {
                leftDots = true;
            }

            if (currentPage < totalPages - (1 + middleSibling)) {
                rightDots = true;
            }
        }

        const lengthRange = Math.min(siblingToShow, totalPages - middleSibling);
        const startRange = currentPage - middleSibling;

        middleRange = range(
            Math.min(Math.max(startRange, 2), totalPages - siblingToShow),
            lengthRange
        );

        return {
            totalPages,
            middleRange,
            leftDots,
            rightDots,
        };
    }, [totalRecords, perPage, siblingCount, currentPage]);

    return paginationRange;
}
