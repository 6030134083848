import React from 'react';
import { getNestedValue } from 'resources/helpers';

/**
 * TBody
 * The body of the table
 *
 * @param {any[]} props - The custom props of the component
 * @param {IColumn[]} props.columns - Represents the settings of every column
 * @param {IResponsePaginated<any[]>} props.data - Represents the data of the table.
 * @returns {JSX.Element} A react component
 */
export function TBody({ columns, data }) {
    return (
        <tbody className="fw-semibold text-gray-600">
            {data?.data?.map((row, index) => {
                return (
                    <tr key={index}>
                        {columns?.map((column, index) => {
                            return (
                                <td key={index} className="pe-0">
                                    {getColumnValue(column, row)}
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
}

function getColumnValue(column, row) {
    if (typeof column.column === 'function') {
        return column.column(row);
    }

    return getNestedValue(row, column.column);
}
