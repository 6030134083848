import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Warehouses Index
 *
 * @returns {JSX.Element} A react component
 */
export function AppModule({
    title,
    description = '',
    breadcrumbs = [],
    children,
    toolbar = null,
}) {
    return (
        <>
            <div id="kt_app_toolbar" className="app-toolbar py-6">
                <div
                    id="kt_app_toolbar_container"
                    className="app-container container-xxl d-flex align-items-start"
                >
                    <div className="d-flex flex-column flex-row-fluid">
                        <div className="d-flex align-items-center pt-1">
                            <ul className="breadcrumb breadcrumb-separatorless fw-semibold">
                                <li className="breadcrumb-item text-white fw-bold lh-1">
                                    <Link to="/" className="text-white asd">
                                        <i className="fonticon-home text-gray-700 fw-bold fs-3"></i>
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <span className="svg-icon svg-icon-4 svg-icon-gray-700 mx-n1">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                                                fill="currentColor"
                                            ></path>
                                        </svg>
                                    </span>
                                </li>

                                {breadcrumbs
                                    .map((breadcrumb, index) => (
                                        <li
                                            key={index}
                                            className="breadcrumb-item text-white fw-bold lh-1"
                                        >
                                            {breadcrumb}
                                        </li>
                                    ))
                                    .reduce((prev, curr) => (
                                        <>
                                            {prev}
                                            <li className="breadcrumb-item">
                                                <span className="svg-icon svg-icon-4 svg-icon-gray-700 mx-n1">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z"
                                                            fill="currentColor"
                                                        ></path>
                                                    </svg>
                                                </span>
                                            </li>
                                            {curr}
                                        </>
                                    ))}
                            </ul>
                        </div>
                        <div className="d-flex flex-stack flex-wrap flex-lg-nowrap gap-4 gap-lg-10 pt-13 pb-6">
                            <div className="page-title me-5">
                                <h1 className="page-heading d-flex text-white fw-bold fs-2 flex-column justify-content-center my-0">
                                    {title}
                                    {!!description && (
                                        <span className="page-desc text-gray-700 fw-semibold fs-6 pt-3">
                                            {description}
                                        </span>
                                    )}
                                </h1>
                            </div>
                            {!!toolbar && (
                                <div className="d-flex align-self-center flex-center flex-shrink-0">
                                    {toolbar}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="app-container container-xxl">
                <div
                    className="app-main flex-column flex-row-fluid"
                    id="kt_app_main"
                >
                    <div className="d-flex flex-column flex-column-fluid">
                        <div
                            id="kt_app_content"
                            className="app-content flex-column-fluid"
                        >
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
