import React from 'react';

export function Footer() {
    return (
        <div className="app-container container-xxl">
            <div
                id="kt_app_footer"
                className="app-footer d-flex flex-column flex-md-row align-items-center flex-center flex-md-stack py-2 py-lg-4"
            >
                <div className="text-dark order-2 order-md-1">
                    <span className="text-muted fw-semibold me-1">
                        2023&copy;
                    </span>
                    <a href="#" className="text-gray-800 text-hover-primary">
                        {process.env.REACT_APP_TITLE}
                    </a>
                </div>
            </div>
        </div>
    );
}
