/**
 * range
 * Make a array of consecutive numbers
 *
 * @param {number} start - The first number of the succession
 * @param {number} length - Lenfth of the array
 * @returns {number[]} A array of numbers
 */
export function range(start = 1, length = 1) {
    return Array.from({ length }, (_, idx) => idx + start);
}
