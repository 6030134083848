import React from 'react';
import { ROLES } from '@resources/constants';
import { Users } from '@pages/admin';

export const menuOptions = [
    {
        title: 'Users',
        name: 'admin.users',
        link: '/admin/users',
        element: <Users />,
        permits: [ROLES.SUPER_ADMIN, ROLES.ROOT],
    },
    // Reports
];

export function getMyMenuArray(myRole) {
    return menuOptions.filter((menu) => menu.permits.includes(myRole));
}
