import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, LoadingOverlay } from '@mantine/core';
import { AppModule, Table } from '@features/components';
import { userService } from '@resources/services';
import { useDatatable } from '@resources/hooks';
import { useGlobalModals } from '@resources/contexts';
import { UserModal } from '@features/modals';

/**
 * module of users
 *
 * @returns {JSX.Element} A react component
 */
export function Users() {
    const { t } = useTranslation();
    const { showModal } = useGlobalModals();
    const { data, updateList, controls, loading } = useDatatable({
        service: userService,
        debounceTime: 500,
    });

    function openModal(modalData = {}) {
        showModal(<UserModal />, {
            userId: modalData.id,
            onSave: updateList,
        });
    }

    const columns = [
        { column: 'id' },
        { title: 'Facebook', column: 'facebook_id' },
        {
            title: 'Stripe',
            column: (row) => (
                <a
                    href={`https://dashboard.stripe.com/customers/${row.stripe_id}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {row.stripe_id}
                </a>
            ),
        },
        {
            title: t('Name'),
            column: (row) => (
                <span>
                    {row.name}
                    {!!row.comments && (
                        <i
                            className="fa fa-comments text-primary ms-2"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={row.comments}
                        ></i>
                    )}
                </span>
            ),
        },
        { title: t('Email'), column: 'email' },
        {
            column: (row) => (
                <span
                    className="btn btn-icon btn-sm btn-primary m-2"
                    onClick={() => openModal(row)}
                >
                    <i className="fa fa-eye"></i>
                </span>
            ),
        },
    ];

    return (
        <AppModule title={`${t('Users')}`} breadcrumbs={['Admin', t('Users')]}>
            <Box pos="relative">
                <LoadingOverlay visible={loading} overlayBlur={2} />
                <Table controls={controls} columns={columns} data={data} />
            </Box>
        </AppModule>
    );
}
