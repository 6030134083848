import React from 'react';
import { Outlet } from 'react-router-dom';

/**
 * Layout Auth of metronic v8.1.6
 *
 * @param {any[]} props - Functional component props
 * @param {JSX.Element} props.children - The children
 * @returns A react component
 */
export function LayoutAuth({ children }) {
    return (
        <div className="d-flex flex-column flex-root">
            <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
                    <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                        <div className="w-lg-500px p-10">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
